import React, { useMemo } from "react"
import { graphql } from "gatsby"
import {
  Layout,
  useTranslate,
} from "@solublestudio/gatsby-theme-soluble-source"

import Section from "../sections"

import { replaceForParams } from "../utils/translations.js"

import { BlogDetail } from "@solublestudio/bdeo-design-system"

export default function Post({ data, pageContext }) {
  const translate = useTranslate()

  const pageData = useMemo(() => data?.page, [data?.page])

  const URL = useMemo(() => encodeURI(pageContext?.pageUrl), [pageContext])
  const URL_PARENT = useMemo(() => encodeURI(data?.parentPage?.slug), [data])

  const PARENT_SECTIONS =
    data?.parentPage?.sections?.length > 0
      ? data?.parentPage?.sections?.filter(
          (section) =>
            section.__typename === "DatoCmsBlogSection" ||
            section.__typename === "DatoCmsHighlightedResourceSection"
        )
      : []

  return (
    <Layout
      navbarFixed={true}
      navbarTransparent={true}
      customSeo={{
        ...pageData?.seo,
        title: pageData?.seo
          ? pageData.seo.title
          : replaceForParams(translate["blogPost.seo.title"]?.text, [
              {
                key: "title",
                value: pageData?.title,
              },
            ]),
        description: pageData?.seo
          ? pageData.seo.description
          : translate["blogPost.seo.description"]?.text,
        image: pageData?.seo?.image?.file || pageData?.seo?.image,
      }}
      footerExtraParams={{
        alternateLanguages: pageContext.seo?.alternateLanguages,
      }}
      headerExtraParams={{
        alternateLanguages: pageContext.seo?.alternateLanguages,
        url: pageContext?.pageUrl,
      }}
    >
      <Section
        {...{
          ...PARENT_SECTIONS[0],
          title: PARENT_SECTIONS[0]?.title,
          subtitle: PARENT_SECTIONS[0]?.subtitle,
          firstPageHref: URL_PARENT,
          dark: true,
          posts: [],
          titleTag: "p",
        }}
      />

      <BlogDetail
        post={data?.page}
        backButton={{
          label: translate["blogPost.back.label"]?.text,
          link: {
            slug: data?.parentPage?.slug,
          },
        }}
        linksSection={{
          title: translate["blogPost.links.title"].text,
          links: {
            twitter: `https://twitter.com/intent/tweet?url=${URL}&text=${encodeURI(
              pageData?.title
            )}`,
            facebook: `https://www.facebook.com/sharer/sharer.php?u=${URL}`,
            linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${URL}`,
            whatsapp: `whatsapp://send?text=${URL}`,
          },
        }}
        relatedPostsSection={{
          title: translate["blogPost.relatedPosts.title"].text,
          posts: data?.relatedPosts?.edges.map(({ node }) => node),
        }}
      />

      <Section {...PARENT_SECTIONS[1]} />
    </Layout>
  )
}

export const query = graphql`
  fragment TagItem on DatoCmsTag {
    title
    slug
  }
  fragment PostItem on DatoCmsBlog {
    title
    slug
    image {
      file {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    cardImageBackup: image {
      file {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    cardImage {
      file {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    cardImageStyle
    tags {
      ...TagItem
    }
    bottomText
  }
  query Post($id: String, $language: String) {
    page: datoCmsBlog(id: { eq: $id }) {
      ...PostItem
      featuredImage: image {
        file {
          childImageSharp {
            fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      seo {
        description
        title
        image {
          file {
            publicURL
            childImageSharp {
              resize(width: 1200, height: 630) {
                src
              }
            }
          }
        }
      }
      content {
        value
        links {
          __typename
          ... on DatoCmsButton {
            id: originalId
            ...Button
          }
          ... on DatoCmsPage {
            id: originalId
            slug
            title
          }
          ... on DatoCmsBlog {
            id: originalId
            slug
            title
          }
          ... on DatoCmsSuccessStory {
            id: originalId
            slug
            title
          }
          ... on DatoCmsWhitepaper {
            id: originalId
            slug
            title
          }
        }
        blocks {
          __typename
          ... on DatoCmsImage {
            id: originalId
            image {
              alt
              file {
                publicURL
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            link {
              file {
                publicURL
              }
            }
            externalSource
            externalLink
            size
            isSmall
          }
          ... on DatoCmsEmbedVideo {
            id: originalId
            url {
              providerUid
            }
            size
          }
        }
      }
    }
    parentPage: getParentPage(locale: $language, type: "DatoCmsBlogSection") {
      ...DatoCmsBlogSectionParentPage
    }
    relatedPosts: allDatoCmsBlog(
      filter: { id: { ne: $id }, locale: { eq: $language } }
      limit: 2
    ) {
      edges {
        node {
          ...PostItem
        }
      }
    }
  }
`
